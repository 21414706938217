import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import Buefy from "buefy";
import "buefy/dist/buefy.css";
import "@mdi/font/css/materialdesignicons.css";

// Socket
// import VueSocketIO from "vue-socket.io";
// import { getWsConfigs } from "@/config/ws.config";
// const socketInstance = new VueSocketIO(getWsConfigs(store));
// Vue.use(socketInstance);

Vue.use(Buefy, {
  defaultIconPack: "mdi",
});

Vue.config.productionTip = false;

new Vue({
  store,
  router,
  render: (h) => h(App),
}).$mount("#app");
