import Vue from "vue";
import Vuex from "vuex";
import app from "./modules/app";
import steering from "./modules/steering";

Vue.use(Vuex);
export default new Vuex.Store({
  modules: {
    app,
    steering,
  },
});
