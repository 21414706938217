/* eslint-disable no-unused-vars */
/* eslint-disable no-empty-pattern */
import ROSLIB from "roslib";

import {
  WS_JOIN_ROOM,
  WS_SEND_REMOTE,
  WS_ON_CONTROL,
  WS_ON_CONNECT,
  WS_ON_DISCONNECT,
  WS_ON_RECONNECT,
  WS_ON_ERROR,
  WS_ON_STREAM,
} from "../type/actions.type";
import { TOPICS } from "@/config/rbw.config";
import { ROS_EVENTS } from "@/config/ros.config";
import { useCloneObj } from "@/utils/use";
import {
  connectToRosBridgeWs,
  rosServiceLogger,
  disconnectToRos,
} from "./utils/ros";

import { getRBWUrl } from "@/utils/base";
import { getCurrentCar } from "@/utils/localStorage";

let $RBWService = null;

const $subscribers = {
  response: null, // Message từ xe
};

const $publishers = {
  remote: null, // Dùng để gửi control tới xe
  joyRemote: null,
};

const $initialRBWState = {
  initialized: false, // Trạng thái đã khởi tạo hay chưa
  connected: false, // Trạng thái đã kết nối với RBW hay chưa
};

const initRos = () => {
  $subscribers.response = new ROSLIB.Topic({
    ros: $RBWService,
    ...TOPICS.RESPONSE_TOPIC,
  });
  $publishers.remote = new ROSLIB.Topic({
    ros: $RBWService,
    ...TOPICS.REMOTE_TOPIC,
  });
  $publishers.joyRemote = new ROSLIB.Service({
    ros: $RBWService,
    ...TOPICS.JOY_REMOTE,
  });
};

const state = {
  ros: useCloneObj($initialRBWState),
  response: {},
  selectedCar: {},
  isSocketConnect: false,
  stream: null,
};

const getters = {
  // ROS getters
  isROSInitialized: ({ ros }) => ros.initialized,
  isROSConnected: ({ ros }) => ros.connected,
  selectedCar: ({ selectedCar }) => selectedCar,
  speed: ({ response }) => response.velocity || 0,
  battery: ({ response }) => response.energy_level || 0,
  isSocketConnect: ({ isSocketConnect }) => isSocketConnect,
  stream: ({ stream }) => stream,
  control: ({ response }) => response.control_mode || "Manual",
  gear: ({ response }) => response.gear || "Neutral",
  state: ({ response }) => response.pnkx_state,
  steering: ({ response }) => response.steering,
};

const mutations = {
  setResponse: (state, payload) => {
    state.response = payload;
  },
  setCar: (state, payload) => {
    state.selectedCar = payload;
  },
  setCamera: (state, payload) => {
    state.camera = payload;
  },
  setControl: (state, payload) => {
    state.control = payload;
  },
  setStream: (state, payload) => {
    state.stream = payload;
  },
};

const actions = {
  initRBWConnection: async ({ state, dispatch }, carIPRBW) => {
    let isValidRBWUrl = false;

    const url = {
      host: carIPRBW,
      port: 9090,
    };

    const destroyTimeout = () => {
      clearTimeout(window.autoReconnectToRBWTimeout);
      delete window.autoReconnectToRBWTimeout;
    };

    const handleRosConnection = (ros) => {
      isValidRBWUrl = true;

      $RBWService = ros;
      $RBWService
        .on(ROS_EVENTS.connection, function () {
          rosServiceLogger.info("Connected to RBW!");

          //clear timer
          destroyTimeout();

          // Khởi tạo subscibe tới ROS
          initRos();

          //Lấy dữ liệu từ RBW
          dispatch("subcribeResponseTopic");

          state.ros.connected = true;
          state.ros.initialized = true;
        })
        .on(ROS_EVENTS.error, function () {
          destroyTimeout();
        })
        .on(ROS_EVENTS.close, function () {
          state.ros.connected = false;

          const RBWUrl = getRBWUrl(url);

          if (isValidRBWUrl && RBWUrl && !window.autoReconnectToRBWTimeout) {
            window.autoReconnectToRBWTimeout = setTimeout(() => {
              connectToRosBridgeWs(RBWUrl, handleRosConnection);
            }, 1000);
          }
        });
    };

    connectToRosBridgeWs(getRBWUrl(url), handleRosConnection);
  },

  disconnectROSConnection: async ({ state }) => {
    disconnectToRos($RBWService);
    state.ros.connected = false;
  },

  subcribeResponseTopic: ({ commit }) => {
    $subscribers.response.subscribe((message) => {
      commit("setResponse", message);
    });
  },

  // eslint-disable-next-line no-empty-pattern
  sendJoyRemote: async ({}, joyMode) => {
    $publishers.joyRemote?.callService({ mode: joyMode }, function (res) {
      console.log("Joy Remote Published Successfully!");
    });
  },

  sendRemote: ({}, payload) => {
    try {
      // khi không thể  kết nối với RBW
      if (!state.ros.connected) {
        throw new Error(`Không thể kết nói với RBW!`);
      }
      $publishers.remote.publish(payload);
    } catch (error) {
      rosServiceLogger.error(error);
    }
  },

  //client listen websocket server conenct
  [WS_ON_CONNECT]({}) {
    state.isSocketConnect = true;
    console.log("Connected to socket!");
  },

  //client listen websocket server reconnect
  [WS_ON_RECONNECT]: ({}) => {
    console.log("Reconnect socket!");
    state.isSocketConnect = true;
  },

  // client listen websocket serer disconnect
  [WS_ON_DISCONNECT]: ({}) => {
    console.log("Disconnected");
    state.isSocketConnect = false;
  },

  [WS_ON_ERROR]: ({}) => {
    console.log("Disconnected");
    state.isSocketConnect = false;
  },

  [WS_JOIN_ROOM]: function ({}, payload) {
    this._vm.$socket.emit("join_room", payload);
  },
  [WS_SEND_REMOTE]: function ({}, payload) {
    this._vm.$socket.emit("g29_control", payload);
  },
  [WS_ON_CONTROL]: function ({ commit }, control) {
    commit("setControl", control);
  },
  [WS_ON_STREAM]: function ({ commit }, stream) {
    commit("setStream", stream);
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
