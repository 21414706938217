import { isJsonString } from "@/utils/is";

const ls = localStorage || window.localStorage;

export const set = (key, value) => ls.setItem(key, value);

export const get = (key) => ls.getItem(key);

export const setJSON = (key, value) => ls.setItem(key, JSON.stringify(value));

export const getJSON = (key) => {
  const data = get(key);

  if (isJsonString(data)) {
    return JSON.parse(data);
  }

  return null;
};

export const remove = (key) => ls.removeItem(key);

export const removeAll = () => ls.clear();

export default {
  set,
  get,
  setJSON,
  getJSON,
  remove,
  removeAll,
};
