import { Ros } from "roslib";
import LoggerService from "@/utils/logger";
import { isFn } from "@/utils/is";
import { removeCurrentCar } from "@/utils/localStorage";

export const rosServiceLogger = new LoggerService("RBW");

export const connectToRosBridgeWs = (rosBridgeWSUrl = undefined, cb) => {
  if (rosBridgeWSUrl) {
    const ros = new Ros();
    try {
      ros.connect(rosBridgeWSUrl);

      if (isFn(cb)) {
        cb(ros);
      }
    } catch (e) {
      console.log(e);
      rosServiceLogger.info(
        `Connecting to RBW need a valid url, but got: ${rosBridgeWSUrl}`
      );
    }
    return ros;
  } else {
    rosServiceLogger.info(
      `Connecting to RBW need a valid url, but got: ${rosBridgeWSUrl}`
    );
  }
};

export const disconnectToRos = (ros) => {
  removeCurrentCar()
  if (ros && ros.isConnected) {
    try {
      ros.close();
      location.reload()
      rosServiceLogger.info(
        `Disconnected to RBW!`
      );
    } catch (error) {
      rosServiceLogger.info(
        `Error when disconnect: ${error}`
      );
    }
  }
}
