import Vue from "vue";
import VueRouter from "vue-router";

const ChooseCar = () => import("../views/ChooseCar.vue");

const GroundControl = () => import("../views/GroundControl.vue");

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "ChooseCar",
    component: ChooseCar,
  },
  {
    path: "/gcs/:carId",
    name: "GroundControl",
    component: GroundControl,
  },
];

const router = new VueRouter({
  routes,
});

export default router;
