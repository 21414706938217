/**
 * Tạo URL RBW (ROSBridge WebSocket) dựa trên các thông tin giao thức, host và port đã cho.
 * Nếu host và port được cung cấp, hàm sẽ trả về URL có giao thức, host và port tương ứng.
 * Nếu không, hàm sẽ trả về null.
 *
 * @param {object} options - Đối tượng chứa thông tin giao thức, host và port.
 * @param {string} options.protocol - Giao thức (protocol) của URL RBW (WebSocket).
 * @param {string} options.host - Host của URL RBW (WebSocket).
 * @param {string|number} options.port - Port của URL RBW (WebSocket).
 * @returns {string|null} - URL RBW hoặc null.
 */
export const getRBWUrl = ({ protocol, host, port }) => {
  if (host && port) {
    return `${protocol ? protocol : "ws"}://${host}:${port}`;
  } else {
    return null;
  }
};

/**
 * Chuyển đổi giá trị từ mét trên giây (m/s) sang kilômét trên giờ (KpH).
 *
 * @param {number} val - Giá trị cần chuyển đổi từ m/s sang KpH.
 * @returns {number} - Giá trị đã chuyển đổi từ m/s sang KpH.
 */
export function MpSToKpH(val) {
  return val * 3.6;
}
