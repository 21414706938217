export const getWsConfigs = (store) => ({
  debug: false,
  connection: process.env.VUE_APP_WS_URL,
  options: {
    withCredentials: true,
  },
  vuex: {
    store,
    actionPrefix: "WS_",
    mutationPrefix: "WS_",
  },
});

//declare event
const CONNECT = "connect";
const ERROR = "error";
const DISCONNECT = "disconnect";
const RECONNECT = "reconnect";

const XBUS_CONTROL = "xbus_control";
const STREAM = "stream";

export const WS_ON = {
  CONNECT,
  DISCONNECT,
  RECONNECT,
  ERROR,
  XBUS_CONTROL,
  STREAM,
};
