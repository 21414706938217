export default class LoggerService {
    //prefix of logger
    constructor(prefix, opts = {}) {
      this.debug = true;
      this.prefix = prefix;
      this.opts = opts;
      this.count = 0;
    }
  
    //show info logger
    info(data, text) {
      if (this.debug) {
        window.console.info(
          `%c[${this.prefix}]` + `%c${text ? text : ""}`,
          "color: white; font-weight: 600; background: #213671; padding: 2px 4px; border-radius: 2px",
          `${
            text
              ? "color: white; font-weight: 600; background: #4690ff; padding: 2px 4px;"
              : ""
          }`,
          data
        );
        this.count++;
        this.reset();
      }
    }
  
    //show error logger
    error() {
      if (this.debug) window.console.error(this.prefix, ...arguments);
      window.console.info(
        `%c[${this.prefix}]` + `%c${"ERROR"}`,
        "color: white; font-weight: 600; background: #213671; padding: 2px 4px; border-radius: 2px",
        "color: white; font-weight: 600; background: red; padding: 2px 4px;",
        ...arguments
      );
  
      this.count++;
      this.reset();
    }
  
    //show warn
    warn() {
      if (this.debug)
        window.console.warn(
          `%c[${this.prefix}]` + `%c${"WARN"}`,
          "color: white; font-weight: 600; background: #213671; padding: 2px 4px; border-radius: 2px",
          "color: white; font-weight: 600; background: orange; padding: 2px 4px;",
          ...arguments
        );
      this.count++;
      this.reset();
    }
  
    reset() {
      if (this.opts?.count) {
        if (this.count === this.opts.count) {
          window.console.clear();
        }
      }
    }
  }
  