const state = {
  isSteeringConnected: false,
  gamepadState: [],
  brake: -1,
  throttle: -1,
};

const getters = {
  isSteeringConnected: ({ isSteeringConnected }) => isSteeringConnected,
  gamepadState: ({ gamepadState }) => gamepadState,
  brake: ({ brake }) => brake,
  throttle: ({ throttle }) => throttle,
};

const mutations = {
  setSteeringStatus: (state, payload) => {
    state.isSteeringConnected = payload;
  },
  setGamePadState: (state, payload) => {
    state.brake = payload?.axes[3];
    state.throttle = payload?.axes[2];
    state.gamepadState = payload;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
};
