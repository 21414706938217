/**
 * Kiểm tra xem một giá trị có phải là một hàm (function) hay không.
 *
 * @param {*} fn - Giá trị cần kiểm tra.
 * @returns {boolean} - Kết quả kiểm tra (true nếu giá trị là một hàm, false nếu không phải).
 */
export const isFn = (fn) => fn && {}.toString.call(fn) === "[object Function]";

/**
 * Kiểm tra xem một chuỗi có phải là một chuỗi JSON hợp lệ hay không.
 *
 * @param {string} str - Chuỗi cần kiểm tra.
 * @returns {boolean} - Kết quả kiểm tra (true nếu chuỗi là một chuỗi JSON hợp lệ, false nếu không phải).
 */
export const isJsonString = (str) => {
    try {
      JSON.parse(str);
    } catch (_e) {
      return false;
    }
    return true;
  };