import {WS_ON} from "@/config/ws.config";

export const WS_JOIN_ROOM = "WsJoinRoom"
export const WS_LEAVE_ROOM = "WsLeaveRoom";

export const WS_SEND_REMOTE = "WsSendRemote"

export const WS_ON_CONNECT = `WS_${WS_ON.CONNECT}`;
export const WS_ON_DISCONNECT = `WS_${WS_ON.DISCONNECT}`;
export const WS_ON_RECONNECT = `WS_${WS_ON.RECONNECT}`;
export const WS_ON_ERROR = `WS_${WS_ON.ERROR}`;

export const WS_ON_CONTROL = `WS_${WS_ON.XBUS_CONTROL}`;
export const WS_ON_STREAM = `WS_${WS_ON.STREAM}`;