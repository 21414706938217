// 1 second = 1000ms = 200ms x5
const THROTTLE_RATE = {
  NO_THROTTLE: 0,
  TWO_MSG_PER_SECOND: 500,
  THREE_MSG_PER_SECOND: 333,
  FIVE_MSG_PER_SECOND: 200,
  ONE_MSG_PER_SECOND: 1000,
  HALF_MSG_PER_SECOND: 2000,
};

const RESPONSE_TOPIC = Object.freeze({
  name: "/api/vehicle/get/status",
  messageType: "pnkx_api_msgs/ApiVehicleStatus",
  throttle_rate: THROTTLE_RATE.NO_THROTTLE,
});

const REMOTE_TOPIC = Object.freeze({
  name: "/joy",
  messageType: "sensor_msgs/Joy",
});

const JOY_REMOTE = Object.freeze({
  name: "/joy_controller/joy_socket",
  serviceType: "pnkx_control_msgs::JoyRemote",
});

export const TOPICS = {
  RESPONSE_TOPIC,
  REMOTE_TOPIC,
  JOY_REMOTE,
};
